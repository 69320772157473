


















































@import '~variables'

.title
  font-weight 400
  margin $space-2
  width 100%
  font-size $fs-h1

